@font-face {
    font-family: 'Blender Pro';
    src: url('./BlenderPro-Book.woff2') format('woff2'),
        url('./BlenderPro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Blender Pro';
    src: url('./BlenderPro-Bold.woff2') format('woff2'),
        url('./BlenderPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Blender Pro';
    src: url('./BlenderPro-Heavy.woff2') format('woff2'),
        url('./BlenderPro-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

